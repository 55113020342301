import restApi from "@/plugins/http";

export class Api {
  execute(method, resource, data, config = null) {
    return new Promise((resolve, reject) => {
      restApi[method](`${resource}`, data, config)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  // auth
  auth(data) {
    return this.execute("post", "token/auth", data);
  }

  // Notification
  getNotifications(data) {
    return this.execute("get", "notification", data);
  }
  updateNotification(id, data) {
    return this.execute("put", `notification/${id}`, data);
  }

  // equipment
  getEquipmentProductiveAssets(data) {
    return this.execute("get", `equipment/productive-asset`, data);
  }
  createEquipmentProductiveAsset(data) {
    return this.execute("post", `equipment/productive-asset`, data);
  }
  updateEquipmentProductiveAsset(productiveAssetId, data) {
    return this.execute(
      "put",
      `equipment/productive-asset/${productiveAssetId}`,
      data
    );
  }
  deleteEquipmentProductiveAsset(productiveAssetId) {
    return this.execute(
      "delete",
      `equipment/productive-asset/${productiveAssetId}`,
      null
    );
  }

  getEquipmentTypes(data) {
    return this.execute("get", `equipment/type`, data);
  }
  getEquipmentType(typeId) {
    return this.execute("get", `equipment/type/${typeId}`, null);
  }
  createEquipmentType(data) {
    return this.execute("post", `equipment/type`, data);
  }
  updateEquipmentType(typeId, data) {
    return this.execute("put", `equipment/type/${typeId}`, data);
  }
  deleteEquipmentType(typeId) {
    return this.execute("delete", `equipment/type/${typeId}`, null);
  }
  getEquipmentTypeParameters(typeId, parameterType, data) {
    return this.execute(
      "get",
      `equipment/type/${typeId}/parameter/${parameterType}`,
      data
    );
  }
  createEquipmentTypeParameter(typeId, parameterType, data) {
    return this.execute(
      "post",
      `equipment/type/${typeId}/parameter/${parameterType}`,
      data
    );
  }
  updateEquipmentTypeParameter(parameterId, parameterType, data) {
    return this.execute(
      "put",
      `equipment/type/parameter/${parameterType}/${parameterId}`,
      data
    );
  }
  updateEquipmentTypeParameterOrder(
    parameterFromId,
    parameterToId,
    parameterType,
    data
  ) {
    return this.execute(
      "put",
      `equipment/type/parameter/${parameterType}/order/${parameterFromId}/${parameterToId}`,
      data
    );
  }
  deleteEquipmentTypeParameter(parameterId, parameterType) {
    return this.execute(
      "delete",
      `equipment/type/parameter/${parameterType}/${parameterId}`,
      null
    );
  }
  getEquipmentManufacturers(data) {
    return this.execute("get", `equipment/manufacturer`, data);
  }
  createEquipmentManufacturer(data) {
    return this.execute("post", `equipment/manufacturer`, data);
  }
  updateEquipmentManufacturer(manufacturerId, data) {
    return this.execute(
      "put",
      `equipment/manufacturer/${manufacturerId}`,
      data
    );
  }
  deleteEquipmentManufacturer(manufacturerId) {
    return this.execute(
      "delete",
      `equipment/manufacturer/${manufacturerId}`,
      null
    );
  }
  getEquipmentModels(data) {
    return this.execute("get", `equipment/model`, data);
  }
  createEquipmentModel(data) {
    return this.execute("post", `equipment/model`, data);
  }
  updateEquipmentModel(modelId, data) {
    return this.execute("put", `equipment/model/${modelId}`, data);
  }
  deleteEquipmentModel(modelId) {
    return this.execute("delete", `equipment/model/${modelId}`, null);
  }

  getCompanies(data) {
    return this.execute("get", `company`, data);
  }
  createCompany(data) {
    return this.execute("post", `company`, data);
  }
  updateCompany(companyId, data) {
    return this.execute("put", `company/${companyId}`, data);
  }
  deleteCompany(companyId) {
    return this.execute("delete", `company/${companyId}`, null);
  }
  activateCompany(companyId) {
    return this.execute("post", `company/${companyId}/activate`, null);
  }
  createCompanyAdmin(companyId, data) {
    return this.execute("post", `company/${companyId}/admin`, data);
  }
  updateCompanyAdmin(companyId, data) {
    return this.execute("put", `company/${companyId}/admin`, data);
  }
  updateCompanySchedule(companyId, data) {
    return this.execute("put", `company/${companyId}/schedule`, data);
  }

  getSafetyAndHealthProtections(data) {
    return this.execute("get", `account/safety-and-health-protection`, data);
  }
  createSafetyAndHealthProtection(data) {
    return this.execute("post", `account/safety-and-health-protection`, data);
  }
  updateSafetyAndHealthProtection(safetyAndHealthProtectionId, data) {
    return this.execute(
      "put",
      `account/safety-and-health-protection/${safetyAndHealthProtectionId}`,
      data
    );
  }
  deleteSafetyAndHealthProtection(safetyAndHealthProtectionId) {
    return this.execute(
      "delete",
      `account/safety-and-health-protection/${safetyAndHealthProtectionId}`,
      null
    );
  }

  getPositions(data) {
    return this.execute("get", `account/position`, data);
  }
  createPosition(data) {
    return this.execute("post", `account/position`, data);
  }
  updatePosition(positionId, data) {
    return this.execute("put", `account/position/${positionId}`, data);
  }
  deletePosition(positionId) {
    return this.execute("delete", `account/position/${positionId}`, null);
  }

  getCertificates(data) {
    return this.execute("get", `account/certificate`, data);
  }
  createCertificate(data) {
    return this.execute("post", `account/certificate`, data);
  }
  updateCertificate(certificateId, data) {
    return this.execute("put", `account/certificate/${certificateId}`, data);
  }
  deleteCertificate(certificateId) {
    return this.execute("delete", `account/certificate/${certificateId}`, null);
  }

  getWorkSchedules(data) {
    return this.execute("get", `account/work-schedule`, data);
  }
  createWorkSchedule(data) {
    return this.execute("post", `account/work-schedule`, data);
  }
  deleteWorkSchedule(workScheduleId) {
    return this.execute(
      "delete",
      `account/work-schedule/${workScheduleId}`,
      null
    );
  }
  deleteUserWorkSchedule(userId, workScheduleId) {
    return this.execute(
      "delete",
      `account/user/${userId}/work-schedule/${workScheduleId}`,
      null
    );
  }
  createWorkScheduleHoliday(workScheduleId, data) {
    return this.execute(
      "post",
      `account/work-schedule/${workScheduleId}/holiday`,
      data
    );
  }

  getTimeTable(data) {
    return this.execute("get", `account/timetable`, data);
  }
  createTimeTable(data) {
    return this.execute("post", `account/timetable`, data);
  }
  updateTimeTable(timeTableId, data) {
    return this.execute("put", `account/timetable/${timeTableId}`, data);
  }
  getTimeTableStatistics(data) {
    return this.execute("get", `account/timetable/statistics`, data);
  }

  getShifts(data) {
    return this.execute("get", `account/shift`, data);
  }
  createShift(data) {
    return this.execute("post", `account/shift`, data);
  }
  updateShift(shiftId, data) {
    return this.execute("put", `account/shift/${shiftId}`, data);
  }
  deleteShift(shiftId) {
    return this.execute("delete", `account/shift/${shiftId}`, null);
  }

  getGeneralSchedules(data) {
    return this.execute("get", `account/schedule/general`, data);
  }

  getShiftWorkSchedules(data) {
    return this.execute("get", `account/schedule/shift-work`, data);
  }
  createShiftWorkSchedule(data) {
    return this.execute("post", `account/schedule/shift-work`, data);
  }
  updateShiftWorkSchedule(shiftWorkId, data) {
    return this.execute(
      "put",
      `account/schedule/shift-work/${shiftWorkId}`,
      data
    );
  }
  deleteShiftWorkSchedule(shiftWorkId) {
    return this.execute(
      "delete",
      `account/schedule/shift-work/${shiftWorkId}`,
      null
    );
  }

  getDepartments(data) {
    return this.execute("get", `department`, data);
  }
  getDepartmentsTree(data) {
    return this.execute("get", `department/tree`, data);
  }
  createDepartment(data) {
    return this.execute("post", `department`, data);
  }
  updateDepartment(departmentId, data) {
    return this.execute("put", `department/${departmentId}`, data);
  }
  deleteDepartment(departmentId) {
    return this.execute("delete", `department/${departmentId}`, null);
  }

  getUsers(data) {
    return this.execute("get", `account/user`, data);
  }
  getUser(userId) {
    return this.execute("get", `account/user/${userId}`, null);
  }
  createUser(data) {
    return this.execute("post", `account/user`, data);
  }
  updateUser(userId, data) {
    return this.execute("put", `account/user/${userId}`, data);
  }
  getUserScheduleHoliday(userId, data) {
    return this.execute("get", `account/user/${userId}/schedule/holiday`, data);
  }
  updateUserScheduleHoliday(userId, data) {
    return this.execute("put", `account/user/${userId}/schedule/holiday`, data);
  }
  deleteUser(userId) {
    return this.execute("delete", `account/user/${userId}`, null);
  }
  activateUser(userId) {
    return this.execute("post", `account/user/${userId}/activate`, null);
  }
  getUserEquipments(userId) {
    return this.execute("get", `account/user/${userId}/equipment`, null);
  }

  getUserProfile() {
    return this.execute("get", `account/me`, null);
  }
  updateUserProfile(data) {
    return this.execute("put", `account/me`, data);
  }

  getEquipments(data) {
    return this.execute("get", `equipment`, data);
  }
  getEquipmentsTree(data) {
    return this.execute("get", `equipment/tree`, data);
  }
  getEquipment(equipmentId) {
    return this.execute("get", `equipment/${equipmentId}`, null);
  }
  createEquipment(data) {
    return this.execute("post", `equipment`, data);
  }
  updateEquipment(equipmentId, data) {
    return this.execute("put", `equipment/${equipmentId}`, data);
  }
  updateEquipmentHandbook(equipmentId, data) {
    return this.execute("put", `equipment/${equipmentId}/handbook`, data);
  }
  getEquipmentFiles(data) {
    return this.execute("get", `equipment/file`, data);
  }

  getEquipmentRepairs(equipmentId, data) {
    return this.execute("get", `equipment/${equipmentId}/repair`, data);
  }
  getEquipmentCalendars(equipmentId, data) {
    return this.execute("get", `equipment/${equipmentId}/calendar`, data);
  }
  deleteEquipmentCalendar(equipmentId, calendarId) {
    return this.execute(
      "delete",
      `equipment/${equipmentId}/calendar/${calendarId}`,
      null
    );
  }
  generateEquipmentCalendar(equipmentId, data) {
    return this.execute("post", `equipment/${equipmentId}/calendar`, data);
  }
  exportEquipmentCalendar(equipmentId, data) {
    return this.execute(
      "post",
      `equipment/${equipmentId}/calendar/export`,
      data
    );
  }
  updateEquipmentCalendarWork(equipmentId, workId, data) {
    return this.execute(
      "put",
      `equipment/${equipmentId}/calendar/work/${workId}`,
      data
    );
  }
  createEquipmentCalendarTechnicalRequirementPerformer(
    equipmentId,
    technicalRequirementId,
    data
  ) {
    return this.execute(
      "post",
      `equipment/${equipmentId}/calendar/technical-requirement/${technicalRequirementId}/performer`,
      data
    );
  }
  deleteEquipmentCalendarTechnicalRequirementPerformer(
    equipmentId,
    technicalRequirementId,
    performerId
  ) {
    return this.execute(
      "delete",
      `equipment/${equipmentId}/calendar/technical-requirement/${technicalRequirementId}/performer/${performerId}`,
      null
    );
  }
  getEquipmentCatalog(equipmentId, data) {
    return this.execute("get", `equipment/${equipmentId}/catalog`, data);
  }
  updateEquipmentScheduleWork(equipmentId, data) {
    return this.execute("put", `equipment/${equipmentId}/schedule/work`, data);
  }
  updateEquipmentSchedule(equipmentId, data) {
    return this.execute("put", `equipment/${equipmentId}/schedule`, data);
  }
  deleteEquipment(equipmentId) {
    return this.execute("delete", `equipment/${equipmentId}`, null);
  }

  getEquipmentGroups(data) {
    return this.execute("get", `equipment/catalog/group`, data);
  }
  createEquipmentGroup(data) {
    return this.execute("post", `equipment/catalog/group`, data);
  }
  getEquipmentGroupChildren(catalogGroupId) {
    return this.execute(
      "get",
      `equipment/catalog/group/${catalogGroupId}/child`,
      null
    );
  }
  updateEquipmentGroup(catalogGroupId, data) {
    return this.execute(
      "put",
      `equipment/catalog/group/${catalogGroupId}`,
      data
    );
  }
  updateEquipmentGroupTag(catalogGroupId, data) {
    return this.execute(
      "put",
      `equipment/catalog/group/${catalogGroupId}/tag`,
      data
    );
  }
  updateEquipmentGroupImg(catalogGroupId, data) {
    return this.execute(
      "put",
      `equipment/catalog/group/${catalogGroupId}/img`,
      data
    );
  }
  deleteEquipmentGroup(catalogGroupId) {
    return this.execute(
      "delete",
      `equipment/catalog/group/${catalogGroupId}`,
      null
    );
  }
  updateEquipmentGroupOrder(catalogGroupFromId, catalogGroupToId) {
    return this.execute(
      "put",
      `equipment/catalog/group/order/${catalogGroupFromId}/${catalogGroupToId}`,
      null
    );
  }

  createEquipmentSparePart(data) {
    return this.execute("post", `equipment/spare-part`, data);
  }
  updateEquipmentSparePart(sparePartId, data) {
    return this.execute("put", `equipment/spare-part/${sparePartId}`, data);
  }
  deleteEquipmentSparePart(sparePartId) {
    return this.execute("delete", `equipment/spare-part/${sparePartId}`, null);
  }
  updateEquipmentSparePartImg(sparePartId, data) {
    return this.execute("put", `equipment/spare-part/${sparePartId}/img`, data);
  }
  updateEquipmentSparePartFiles(sparePartId, data) {
    return this.execute(
      "put",
      `equipment/spare-part/${sparePartId}/file`,
      data
    );
  }

  updateEquipmentSkipNorm(equipmentId, data) {
    return this.execute("put", `equipment/${equipmentId}/skip-norm`, data);
  }
  getEquipmentMonitorings(equipmentId, data) {
    return this.execute("get", `equipment/${equipmentId}/monitoring`, data);
  }
  createEquipmentMonitoring(equipmentId, data) {
    return this.execute("post", `equipment/${equipmentId}/monitoring`, data);
  }
  updateEquipmentMonitoring(equipmentId, monitoringId, data) {
    return this.execute(
      "put",
      `equipment/${equipmentId}/monitoring/${monitoringId}`,
      data
    );
  }
  deleteEquipmentMonitoring(equipmentId, monitoringId) {
    return this.execute(
      "delete",
      `equipment/${equipmentId}/monitoring/${monitoringId}`,
      null
    );
  }
  getEquipmentMonitoringAnalytics(equipmentId, data) {
    return this.execute(
      "get",
      `equipment/${equipmentId}/monitoring/analytics`,
      data
    );
  }

  getObjects(data) {
    return this.execute("get", `object`, data);
  }
  getObjectsTree(data) {
    return this.execute("get", `object/tree`, data);
  }
  createObject(data) {
    return this.execute("post", `object`, data);
  }
  getObjectChildren(objectId) {
    return this.execute("get", `object/${objectId}/child`, null);
  }
  updateObject(objectId, data) {
    return this.execute("put", `object/${objectId}`, data);
  }
  deleteObject(objectId) {
    return this.execute("delete", `object/${objectId}`, null);
  }

  createArea(data) {
    return this.execute("post", `object/area`, data);
  }
  getAreas(data) {
    return this.execute("get", `object/area`, data);
  }
  getArea(areaId) {
    return this.execute("get", `object/area/${areaId}`, null);
  }
  updateArea(areaId, data) {
    return this.execute("put", `object/area/${areaId}`, data);
  }
  deleteArea(areaId) {
    return this.execute("delete", `object/area/${areaId}`, null);
  }
  getAreaEquipment(areaId, data) {
    return this.execute("get", `object/area/${areaId}/equipment`, data);
  }
  updateAreaEquipment(areaId, data) {
    return this.execute("put", `object/area/${areaId}/equipment`, data);
  }
  updateAreaSchedule(areaId, data) {
    return this.execute("put", `object/area/${areaId}/schedule`, data);
  }
  updateAreaResponsible(areaId, data) {
    return this.execute("put", `object/area/${areaId}/responsible`, data);
  }

  getDefects(data) {
    return this.execute("get", `defect`, data);
  }
  getDefect(defectId) {
    return this.execute("get", `defect/${defectId}`, null);
  }
  createDefect(data) {
    return this.execute("post", `defect`, data);
  }
  updateDefect(defectId, data) {
    return this.execute("put", `defect/${defectId}`, data);
  }
  updateDefectStatus(defectId, data) {
    return this.execute("put", `defect/${defectId}/status`, data);
  }
  deleteDefect(defectId) {
    return this.execute("delete", `defect/${defectId}`, null);
  }

  getAnalyticsOverallKTG(data) {
    return this.execute("get", `report/analytics/overall/ktg`, data);
  }
  getAnalyticsDefectType(data) {
    return this.execute("get", `report/analytics/defect/type`, data);
  }
  getAnalyticsOverallEquipmentCondition(data) {
    return this.execute(
      "get",
      `report/analytics/overall/equipment-condition`,
      data
    );
  }
  getAnalyticsDefectCount(data) {
    return this.execute("get", `report/analytics/defect/count`, data);
  }
  getAnalyticsWPPStatus(data) {
    return this.execute("get", `report/analytics/wpp/status`, data);
  }
  getAnalyticsWPPCount(data) {
    return this.execute("get", `report/analytics/wpp/count`, data);
  }
  getAnalyticsDefectFixTime(data) {
    return this.execute("get", `report/analytics/defect/fix-time`, data);
  }
  getAnalyticsDefectAppearanceTime(data) {
    return this.execute("get", `report/analytics/defect/appearance-time`, data);
  }
  getAnalyticsWPPTransfer(data) {
    return this.execute("get", `report/analytics/wpp/transfer`, data);
  }
  getAnalyticsWPPPlanned(data) {
    return this.execute("get", `report/analytics/wpp/planned`, data);
  }
  getAnalyticsWPPFinished(data) {
    return this.execute("get", `report/analytics/wpp/finished`, data);
  }
  getAnalyticsWPPKpi(data) {
    return this.execute("get", `report/analytics/wpp/kpi`, data);
  }
  getAnalyticsRegulationCount(data) {
    return this.execute("get", `report/analytics/regulation/count`, data);
  }
  getAnalyticsRegulationType(data) {
    return this.execute("get", `report/analytics/regulation/type`, data);
  }

  getRegulationForms(data) {
    return this.execute("get", `regulation/form`, data);
  }
  getRegulationForm(formId) {
    return this.execute("get", `regulation/form${formId}`, null);
  }
  createRegulationForm(data) {
    return this.execute("post", `regulation/form`, data);
  }
  updateRegulationForm(formId, data) {
    return this.execute("put", `regulation/form/${formId}`, data);
  }
  deleteRegulationForm(formId) {
    return this.execute("delete", `regulation/form/${formId}`, null);
  }

  getRegulations(data) {
    return this.execute("get", `regulation`, data);
  }
  createRegulation(data) {
    return this.execute("post", `regulation`, data);
  }
  updateRegulation(regulationId, data) {
    return this.execute("put", `regulation/${regulationId}`, data);
  }
  deleteRegulation(regulationId) {
    return this.execute("delete", `regulation/${regulationId}`, null);
  }

  getRegulationSchedules(data) {
    return this.execute("get", `regulation/schedule`, data);
  }
  createRegulationSchedule(data) {
    return this.execute("post", `regulation/schedule`, data);
  }
  getRegulationSchedule(scheduleId) {
    return this.execute("get", `regulation/schedule/${scheduleId}`, null);
  }
  updateRegulationSchedule(scheduleId, data) {
    return this.execute("put", `regulation/schedule/${scheduleId}`, data);
  }
  deleteRegulationSchedule(scheduleId) {
    return this.execute("delete", `regulation/schedule/${scheduleId}`, null);
  }

  getRegulationHistories(data) {
    return this.execute("get", `regulation/history`, data);
  }
  getRegulationHistoryGraphs(data) {
    return this.execute("get", `regulation/history/graph`, data);
  }
  generateRegulationHistoryExcel(data) {
    return this.execute("get", `regulation/history/excel`, data);
  }
  getRegulationHistoryAuthors(data) {
    return this.execute("get", `regulation/history/author`, data);
  }
  getRegulationHistory(historyId, data) {
    return this.execute("get", `regulation/history/${historyId}`, data);
  }
  startRegulationHistory(historyId) {
    return this.execute("post", `regulation/history/${historyId}/start`, null);
  }
  finishRegulationHistory(historyId, data) {
    return this.execute("post", `regulation/history/${historyId}/finish`, data);
  }

  getWPPWorks(data) {
    return this.execute("get", `wpp/work`, data);
  }
  createWPPWork(data) {
    return this.execute("post", `wpp/work`, data);
  }
  updateWPPWork(workId, data) {
    return this.execute("put", `wpp/work/${workId}`, data);
  }
  deleteWPPWork(workId) {
    return this.execute("delete", `wpp/work/${workId}`, null);
  }

  getWPPHandbooks(data) {
    return this.execute("get", `wpp/handbook`, data);
  }
  createWPPHandbook(data) {
    return this.execute("post", `wpp/handbook`, data);
  }
  updateWPPHandbook(handbookId, data) {
    return this.execute("put", `wpp/handbook/${handbookId}`, data);
  }
  deleteWPPHandbook(handbookId) {
    return this.execute("delete", `wpp/handbook/${handbookId}`, null);
  }

  getWorkOrderProtectionMeans(data) {
    return this.execute("get", `work-order/protection-mean`, data);
  }
  createWorkOrderProtectionMean(data) {
    return this.execute("post", `work-order/protection-mean`, data);
  }
  updateWorkOrderProtectionMean(protectionMeanId, data) {
    return this.execute(
      "put",
      `work-order/protection-mean/${protectionMeanId}`,
      data
    );
  }
  deleteWorkOrderProtectionMean(protectionMeanId) {
    return this.execute(
      "delete",
      `work-order/protection-mean/${protectionMeanId}`,
      null
    );
  }

  getWorkOrderCustomers(data) {
    return this.execute("get", `work-order/customer`, data);
  }
  createWorkOrderCustomer(data) {
    return this.execute("post", `work-order/customer`, data);
  }
  updateWorkOrderCustomer(customerId, data) {
    return this.execute("put", `work-order/customer/${customerId}`, data);
  }
  deleteWorkOrderCustomer(customerId) {
    return this.execute("delete", `work-order/customer/${customerId}`, null);
  }

  getWorkOrderSecurityMeasures(data) {
    return this.execute("get", `work-order/security-measure`, data);
  }
  createWorkOrderSecurityMeasure(data) {
    return this.execute("post", `work-order/security-measure`, data);
  }
  updateWorkOrderSecurityMeasure(securityMeasureId, data) {
    return this.execute(
      "put",
      `work-order/security-measure/${securityMeasureId}`,
      data
    );
  }
  deleteWorkOrderSecurityMeasure(securityMeasureId) {
    return this.execute(
      "delete",
      `work-order/security-measure/${securityMeasureId}`,
      null
    );
  }

  getWorkOrders(data) {
    return this.execute("get", `work-order`, data);
  }
  getWorkOrder(workOrderId) {
    return this.execute("get", `work-order/${workOrderId}`, null);
  }
  updateWorkOrder(workOrderId, data) {
    return this.execute("put", `work-order/${workOrderId}`, data);
  }
  updateWorkOrderWork(workOrderId, workId, data) {
    return this.execute(
      "put",
      `work-order/${workOrderId}/work/${workId}`,
      data
    );
  }
  createWorkOrderComment(workOrderId, data) {
    return this.execute("post", `work-order/${workOrderId}/comment`, data);
  }
  getWorkOrderComments(workOrderId) {
    return this.execute("get", `work-order/${workOrderId}/comment`, null);
  }
  finishWorkOrder(workOrderId) {
    return this.execute("post", `work-order/${workOrderId}/finish`, null);
  }
  createWorkOrder(data) {
    return this.execute("post", `work-order`, data);
  }

  /* Work Order 2 */
  getWorkOrders2(data) {
    return this.execute("get", `work-order2`, data);
  }
  createWorkOrder2(data) {
    return this.execute("post", `work-order2`, data);
  }
  createWorkOrder2WPP2(data) {
    return this.execute("post", `work-order2/wpp2`, data);
  }
  getWorkOrder2(id, data) {
    return this.execute("get", `work-order2/${id}`, data);
  }
  updateWorkOrder2(id, data) {
    return this.execute("put", `work-order2/${id}`, data);
  }
  deleteWorkOrder2(id, data) {
    return this.execute("delete", `work-order2/${id}`, data);
  }
  updateStatusWorkOrder2(id, data) {
    return this.execute("put", `work-order2/${id}/status`, data);
  }

  getAggregateJournals(data) {
    return this.execute("get", `aggregate-journal`, data);
  }
  createAggregateJournal(data) {
    return this.execute("post", `aggregate-journal`, data);
  }
  deleteAggregateJournal(aggregateJournalId) {
    return this.execute(
      "delete",
      `aggregate-journal/${aggregateJournalId}`,
      null
    );
  }

  getReportEquipmentCondition(data) {
    return this.execute("get", `report/equipment-condition`, data);
  }
  exportReportEquipmentCondition(data) {
    return this.execute("get", `report/equipment-condition/export`, data);
  }
  getReportEquipmentConditionAnalytics(data) {
    return this.execute("get", `report/equipment-condition/analytics`, data);
  }
  getReportWorkOrderAnalytics(data) {
    return this.execute("get", `report/work-order/analytics`, data);
  }
  generateWorkOrderExcel(data) {
    return this.execute("post", `report/work-order/excel`, data);
  }

  getWarehouses(data) {
    return this.execute("get", `warehouse`, data);
  }
  createWarehouse(data) {
    return this.execute("post", `warehouse`, data);
  }
  updateWarehouse(warehouseId, data) {
    return this.execute("put", `warehouse/${warehouseId}`, data);
  }
  deleteWarehouse(warehouseId) {
    return this.execute("delete", `warehouse/${warehouseId}`, null);
  }

  getSafetyDocuments(data) {
    return this.execute("get", `safety/document`, data);
  }
  createSafetyDocument(data) {
    return this.execute("post", `safety/document`, data);
  }
  updateSafetyDocument(documentId, data) {
    return this.execute("put", `safety/document/${documentId}`, data);
  }
  deleteSafetyDocument(documentId) {
    return this.execute("delete", `safety/document/${documentId}`, null);
  }

  getSafetyViolations(data) {
    return this.execute("get", `safety/violation`, data);
  }
  createSafetyViolation(data) {
    return this.execute("post", `safety/violation`, data);
  }
  updateSafetyViolation(violationId, data) {
    return this.execute("put", `safety/violation/${violationId}`, data);
  }
  deleteSafetyViolation(violationId) {
    return this.execute("delete", `safety/violation/${violationId}`, null);
  }
  finishSafetyViolation(violationId) {
    return this.execute("post", `safety/violation/${violationId}/finish`, null);
  }

  getReports(data) {
    return this.execute("get", "report", data);
  }
  getReport(id, data) {
    return this.execute("get", `report/${id}`, data);
  }
  deleteReport(id, data) {
    return this.execute("delete", `report/${id}`, data);
  }
  getReportExcel(data) {
    return this.execute("get", "report/excel", data);
  }
  postReportExcel(data) {
    return this.execute("post", "report/excel", data);
  }

  getReportStatement(data) {
    return this.execute("get", `report/statement`, data);
  }
  getReportStatementDetail(id, data) {
    return this.execute("get", `report/statement/${id}`, data);
  }
  deleteReportStatement(id, data) {
    return this.execute("delete", `report/statement/${id}`, data);
  }
  getReportStatementExcel(data) {
    return this.execute("get", `report/statement/excel`, data);
  }
  postReportStatementExcel(data) {
    return this.execute("post", `report/statement/excel`, data);
  }
  getReportStatementRecipient(data) {
    return this.execute("get", `report/statement/recipient`, data);
  }
  postReportStatementRecipient(data) {
    return this.execute("post", `report/statement/recipient`, data);
  }
  getRecipient(data) {
    return this.execute("get", "report/recipient", data);
  }
  postRecipient(data) {
    return this.execute("post", "report/recipient", data);
  }

  // Meter Data
  getMeterData(data) {
    return this.execute("get", "meter-data", data);
  }
  getMeterDataPower(data) {
    return this.execute("get", "meter-data/power", data);
  }
  getMeterDataRecipient(data) {
    return this.execute("get", "meter-data/recipient", data);
  }
  postMeterDataRecipient(data) {
    return this.execute("post", "meter-data/recipient", data);
  }
  getMeterDataExcel(data) {
    return this.execute("get", "meter-data/excel", data);
  }
  getMeterDataProductionDaily(data) {
    return this.execute("get", "meter-data/production-daily", data);
  }
  postMeterDataProductionDaily(data) {
    return this.execute("post", "meter-data/production-daily", data);
  }
  updateMeterDataProductionDaily(id, data) {
    return this.execute("put", `meter-data/production-daily/${id}`, data);
  }
  deleteMeterDataProductionDaily(id, data) {
    return this.execute("delete", `meter-data/production-daily/${id}`, data);
  }
  getMeterDataDailyChartTotal(data) {
    return this.execute("get", `meter-data/daily-chart-total`, data);
  }
  getMeterDataDailyChartTotalDetail(id, data) {
    return this.execute("get", `meter-data/daily-chart-total/${id}`, data);
  }
  deleteMeterDataDailyChartTotal(id, data) {
    return this.execute("delete", `meter-data/daily-chart-total/${id}`, data);
  }
  postMeterDataDailyChartExcel(data) {
    return this.execute("post", `meter-data/daily-chart/excel`, data);
  }
  postMeterDataProductionPlanExcel(data) {
    return this.execute("post", `meter-data/production-plan/excel`, data);
  }
  getMeterDataProductionPlan(data) {
    return this.execute("get", `meter-data/production-plan`, data);
  }
  getMeterDataProductionPlanDetail(id, data) {
    return this.execute("get", `meter-data/production-plan/${id}`, data);
  }
  deleteMeterDataProductionPlan(id, data) {
    return this.execute("delete", `meter-data/production-plan/${id}`, data);
  }

  /* WPP 2 */
  getWPP2(data) {
    return this.execute("get", `wpp2`, data);
  }
  createWPP2(data) {
    return this.execute("post", `wpp2`, data);
  }
  updateWPP2(id, data) {
    return this.execute("put", `wpp2/${id}`, data);
  }
  deleteWPP2(id, data) {
    return this.execute("delete", `wpp2/${id}`, data);
  }

  /* Handbook app */
  getHandbookAppWorkType(data) {
    return this.execute("get", "handbook-app/work-type", data);
  }
  postHandbookAppWorkType(data) {
    return this.execute("post", "handbook-app/work-type", data);
  }
  putHandbookAppWorkType(id, data) {
    return this.execute("put", `handbook-app/work-type/${id}`, data);
  }
  deleteHandbookAppWorkType(id) {
    return this.execute("delete", `handbook-app/work-type/${id}`);
  }
  getHandbookAppRanks(data) {
    return this.execute("get", `handbook-app/rank`, data);
  }
  postHandbookAppRank(data) {
    return this.execute("post", "handbook-app/rank", data);
  }
  putHandbookAppRank(id, data) {
    return this.execute("put", `handbook-app/rank/${id}`, data);
  }
  deleteHandbookAppRank(id) {
    return this.execute("delete", `handbook-app/rank/${id}`);
  }
  getHandbookAppPositions(data) {
    return this.execute("get", `handbook-app/position`, data);
  }
  postHandbookAppPosition(data) {
    return this.execute("post", "handbook-app/position", data);
  }
  putHandbookAppPosition(id, data) {
    return this.execute("put", `handbook-app/position/${id}`, data);
  }
  deleteHandbookAppPosition(id) {
    return this.execute("delete", `handbook-app/position/${id}`);
  }
  getHandbookAppTechnicalPlaces(data) {
    return this.execute("get", `handbook-app/technical-place`, data);
  }
  postHandbookAppTechnicalPlace(data) {
    return this.execute("post", "handbook-app/technical-place", data);
  }
  putHandbookAppTechnicalPlace(id, data) {
    return this.execute("put", `handbook-app/technical-place/${id}`, data);
  }
  deleteHandbookAppTechnicalPlace(id) {
    return this.execute("delete", `handbook-app/technical-place/${id}`);
  }

  getSHPS(data) {
    return this.execute("get", "meter-data/shps", data);
  }
  createSHPS(data) {
    return this.execute("post", "meter-data/shps", data);
  }
  getExcelSHPS(data) {
    return this.execute("get", "meter-data/shps/excel", data);
  }
  getSHPSDetail(id, data) {
    return this.execute("get", `meter-data/shps/${id}`, data);
  }
  updateSHPS(id, data) {
    return this.execute("put", `meter-data/shps/${id}`, data);
  }
  deleteSHPS(id) {
    return this.execute("delete", `meter-data/shps/${id}`);
  }
  updateSHPSdata(id, data) {
    return this.execute("put", `meter-data/shps/data/${id}`, data);
  }
}
